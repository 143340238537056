import { Injectable } from '@angular/core';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { OvertimePolicyModel } from './overtime-policy.model';

@Injectable()
export class OvertimePolicyApi {
  private endpoint = 'overtime/policy/';

  public constructor(private gateway: ApiGateway) {}

  public load() {
    return this.gateway.get(this.endpoint);
  }

  public add(overtimePolicy: OvertimePolicyModel) {
    return this.gateway.post(this.endpoint, overtimePolicy);
  }

  public update(overtimePolicy: OvertimePolicyModel) {
    return this.gateway.put(this.endpoint + overtimePolicy.id, overtimePolicy);
  }

  public delete(overtimePolicyId: string) {
    return this.gateway.delete(this.endpoint + overtimePolicyId);
  }
}

import { Injectable } from '@angular/core';
import { OvertimePolicyApi } from './overtime-policy.api';
import { AppState } from '../../index';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { OvertimePolicyModel } from './overtime-policy.model';
import {
  overtimePolicyAddSuccess,
  overtimePolicyDeleteSuccess,
  overtimePolicyLoadSuccess,
  overtimePolicyUpdateSuccess,
} from './overtime-policy.action';

@Injectable()
export class OvertimePolicyService {
  public constructor(private api: OvertimePolicyApi, private store: Store<AppState>) {}

  public load() {
    return this.api.load().pipe(
      tap((res) => {
        this.store.dispatch(overtimePolicyLoadSuccess({ overtimePolicies: res }));
      })
    );
  }

  public add(overtimePolicy: OvertimePolicyModel) {
    return this.api.add(overtimePolicy).pipe(
      tap((res) => {
        this.store.dispatch(overtimePolicyAddSuccess({ overtimePolicy: res }));
      })
    );
  }

  public update(overtimePolicy: OvertimePolicyModel) {
    return this.api.update(overtimePolicy).pipe(
      tap((res) => {
        this.store.dispatch(overtimePolicyUpdateSuccess({ overtimePolicy: res }));
      })
    );
  }

  public delete(overtimePolicyId: string) {
    return this.api.delete(overtimePolicyId).pipe(
      tap(() => {
        this.store.dispatch(overtimePolicyDeleteSuccess({ overtimePolicyId: overtimePolicyId }));
      })
    );
  }
}

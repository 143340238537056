import { OvertimePolicyModel } from './overtime-policy.model';
import { createAction, props } from '@ngrx/store';

export const overtimePolicyLoadSuccess = createAction(
  '[OvertimePolicy] LoadSuccess',
  props<{ overtimePolicies: OvertimePolicyModel[] }>()
);

export const overtimePolicyAddSuccess = createAction(
  '[OvertimePolicy] AddSuccess',
  props<{ overtimePolicy: OvertimePolicyModel }>()
);

export const overtimePolicyUpdateSuccess = createAction(
  '[OvertimePolicy] UpdateSuccess',
  props<{ overtimePolicy: OvertimePolicyModel }>()
);

export const overtimePolicyDeleteSuccess = createAction(
  '[OvertimePolicy] DeleteSuccess',
  props<{ overtimePolicyId: string }>()
);
